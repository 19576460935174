import React, { useCallback, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../styles/footer.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../../locales/i18n"; // i18n 임포트

export default function Footer() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const instagram =
    windowWidth <= 767
      ? "./image/mobile/instagram.svg"
      : "./image/pc/instagram.svg";

  const linkedin =
    windowWidth <= 767
      ? "./image/mobile/linkedin.svg"
      : "./image/pc/linkedin.svg";

  return (
    <div className="footer_container">
      <div className="footer_child_container">
        <Link to={"/"}>
          <div>
            <img src="/image/pc/footer-logo.svg"></img>
          </div>
        </Link>
        <div className="footer_text_container">
          <div className="footer_nav_container">
            <ul>
              <Link to={"/about-us"}>
                <ol>About us</ol>
              </Link>
              <Link to={"/service"}>
                <ol>Service</ol>
              </Link>
              <Link to={"/communication"}>
                <ol>Communication</ol>
              </Link>
              <Link to={"/clients-partners"}>
                <ol>Clients & Parthners</ol>
              </Link>
              <Link to={"/contact-us"}>
                <ol>Contact us</ol>
              </Link>
            </ul>
          </div>
          <div className="footer_info_container">
            <ul>
              <a
                href="https://rural-act-846.notion.site/a6ea264bd1e34f45aff1efe055081b8b?pvs=4"
                target="_blank"
              >
                <ol
                  dangerouslySetInnerHTML={{
                    __html: t("footer.privacy"),
                  }}
                ></ol>
              </a>
              {/* <ol
                dangerouslySetInnerHTML={{
                  __html: t("footer.LegalNotice"),
                }}
              ></ol>
              <ol
                dangerouslySetInnerHTML={{
                  __html: t("footer.Cookie"),
                }}
              ></ol>
              <ol
                dangerouslySetInnerHTML={{
                  __html: t("footer.Accessibility"),
                }}
              ></ol>
              <ol
                dangerouslySetInnerHTML={{
                  __html: t("footer.SiteMap"),
                }}
              ></ol> */}
            </ul>
            <div className="sns_icon">
              <a
                href="https://www.instagram.com/copilotzplus?igsh=ZDE0OTdsaHNzZTZr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="sns_instagram"></div>
              </a>
              <a
                href="https://www.linkedin.com/company/copilotzplus/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="sns_linkedin"></div>
              </a>
            </div>
          </div>
          <p
            className="footer_adress"
            dangerouslySetInnerHTML={{
              __html: t("footer.adress"),
            }}
          ></p>
          <p className="footer_copyright">
            ⓒ 2023. copilotz+. all rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
