import React, { useCallback, useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "../../../styles/sales.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../../locales/i18n";

export default function Sales() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    // 언어 변경 이벤트 리스너
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };
    i18n.on("languageChanged", handleLanguageChange);
    // 이벤트 리스너 해제
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const slickRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);

  const goToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
    slickRef.current.slickGoTo(slideIndex);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  const activeStyle = isMobile
    ? {
        color: "#FF9000",
        fontFamily: "Pretendard",
        fontSize: "1rem",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "118.836%", // 1.18838rem
        textDecorationLine: "underline",
      }
    : {
        color: "#FF9000",
        fontFamily: "Pretendard",
        fontSize: "1.375rem",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "118.836%", // 1.634rem
        textDecorationLine: "underline",
      };
  const getStyle = (slideIndex) => {
    return currentSlide === slideIndex ? activeStyle : {};
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const arrowImage =
    windowWidth <= 767
      ? "./image/mobile/moreArrow-black.svg"
      : "./image/pc/moreArrow-black.svg";

  return (
    <div className="sales_container">
      <div className="sales_child_container">
        <h3
          dangerouslySetInnerHTML={{
            __html: t("main.salesTitle"),
          }}
        ></h3>
        <p
          dangerouslySetInnerHTML={{
            __html: t("main.salesDescription"),
          }}
        ></p>
        <ul>
          <ol style={getStyle(0)} onClick={() => goToSlide(0)}>
            Service
          </ol>
          <ol style={getStyle(1)} onClick={() => goToSlide(1)}>
            Director
          </ol>
          <ol style={getStyle(2)} onClick={() => goToSlide(2)}>
            Solution
          </ol>
        </ul>
        <div className="sales_slider">
          <Slider {...settings} ref={slickRef}>
            <div>
              <div className="sales_slider_container">
                <div className="sales_slide_service"></div>
                <div className="sales_slide_content">
                  <h4>Service</h4>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: t("main.salesServiceTitle"),
                    }}
                  ></h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("main.salesServiceDescription"),
                    }}
                  ></p>
                  <Link to={"/service"}>
                    <span>
                      {t("main.knowhowMore")}
                      <img src={arrowImage} style={{ marginLeft: "5px" }}></img>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <div className="sales_slider_container">
                <div className="sales_slide_director"></div>
                <div className="sales_slide_content">
                  <h4>Director</h4>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: t("main.salesDirectorTitle"),
                    }}
                  ></h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("main.salesDirectorDescription"),
                    }}
                  ></p>
                  <Link to={"/service"}>
                    <span>
                      {t("main.knowhowMore")}
                      <img src={arrowImage} style={{ marginLeft: "5px" }}></img>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <div className="sales_slider_container">
                <div className="sales_slide_solution"></div>
                <div className="sales_slide_content">
                  <h4>Solution</h4>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: t("main.salesSolutionTitle"),
                    }}
                  ></h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("main.salesSolutionDescription"),
                    }}
                  ></p>
                  <Link to={"/service"}>
                    <span>
                      {t("main.knowhowMore")}
                      <img src={arrowImage} style={{ marginLeft: "5px" }}></img>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
          <div onClick={previous} className="left-arrow">
            <img src="./image/pc/l-arrow.svg" alt={"pre-arrow"} />
          </div>
          <div onClick={next} className="right-arrow">
            <img src="./image/pc/r-arrow.svg" alt={"next-arrow"} />
          </div>
        </div>
      </div>
    </div>
  );
}
