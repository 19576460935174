import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import React, { useState, useEffect } from "react";
import "../../styles/notfound.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

export default function NotFound() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sandclockStyle =
    windowWidth > 767
      ? { marginTop: "8.69rem", marginBottom: "4.94rem" } // 768px 이상
      : { marginTop: "3.75rem", marginBottom: "3.75rem" }; // 767px 이하

  const sandclockImg =
    windowWidth > 767
      ? "/image/pc/notfound-sandclock.svg"
      : "/image/mobile/sand-clock 1.svg"; // 이미지 경로 조건부 설정

  const dot =
    windowWidth > 767
      ? { marginLeft: "2.62rem", marginRight: "2.62rem" } // 768px 이상
      : { marginLeft: "1rem", marginRight: "1rem" }; // 767px 이하

  const dotImg =
    windowWidth > 767 ? "/image/pc/notfound-dot2.svg" : "/image/mobile/dot.svg"; // 이미지 경로 조건부 설정

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="not_found_container">
        <div>
          <img src={sandclockImg} style={sandclockStyle}></img>
        </div>
        <div className="not_found_dot_container">
          {language === "ko" && <img src={dotImg}></img>}
          {language === "ko" && <img src={dotImg} style={dot}></img>}
          {language === "ko" && <img src={dotImg}></img>}
        </div>
        <h2
          dangerouslySetInnerHTML={{
            __html: t("notFound.title"),
          }}
        ></h2>
        <p
          dangerouslySetInnerHTML={{
            __html: t("notFound.description"),
          }}
        ></p>
      </div>
      <Footer />
    </div>
  );
}
