import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import React, { useState, useEffect } from "react";
import "../../styles/service.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

export default function Service() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const infoLogo =
    windowWidth <= 767
      ? "./image/mobile/service-info-logo.svg"
      : "./image/pc/service-info-logo.svg";

  return (
    <div>
      <Header />
      <div className="service_container">
        <div className="service_banner">
          <div className="service_banner_text">
            <h3>Service</h3>
            <h2
              dangerouslySetInnerHTML={{
                __html: t("service.bannerTitle"),
              }}
            ></h2>
          </div>
        </div>
        <div className="service_info_container">
          <div className="service_info_child_container">
            <div className="service_info_logo">
              <img src={infoLogo}></img>
            </div>
            <div className="service_info_text">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("service.info"),
                }}
              ></p>
            </div>
          </div>
        </div>
        <div className="service_solution">
          <h3
            dangerouslySetInnerHTML={{
              __html: t("service.solutionTitle"),
            }}
          ></h3>
          <div className="service_solution_back">
            <div className="service_solution_img">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("service.solution1"),
                }}
              ></p>
            </div>
            <div className="service_solution_img">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("service.solution2"),
                }}
              ></p>
            </div>
            <div className="service_solution_img">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("service.solution3"),
                }}
              ></p>
            </div>
          </div>
          <div className="service_solution_back">
            <div className="service_solution_img">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("service.solution4"),
                }}
              ></p>
            </div>
            <div className="service_solution_img">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("service.solution5"),
                }}
              ></p>
            </div>
            <div className="service_solution_img">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("service.solution6"),
                }}
              ></p>
            </div>
          </div>
        </div>
        <div className="service_marketing_container">
          <h3
            dangerouslySetInnerHTML={{
              __html: t("service.marketingTitle"),
            }}
          ></h3>
          <div className="service_marketing_step_container">
            <div className="service_marketing_step">
              <h6>step. 01</h6>
              <h5
                dangerouslySetInnerHTML={{
                  __html: t("service.step1Title"),
                }}
              ></h5>
              <div className="service_marketing_step_content">
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step1Item1"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step1Item2"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step1Item3"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step1Item4"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step1Item5"),
                    }}
                  ></li>
                </ul>
              </div>
            </div>

            <div className="service_marketing_step">
              <h6>step. 02</h6>
              <h5
                dangerouslySetInnerHTML={{
                  __html: t("service.step2Title"),
                }}
              ></h5>
              <div className="service_marketing_step_content">
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step2Item1"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step2Item2"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step2Item3"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step2Item4"),
                    }}
                  ></li>
                </ul>
              </div>
            </div>

            <div className="service_marketing_step">
              <h6>step. 03</h6>
              <h5
                dangerouslySetInnerHTML={{
                  __html: t("service.step3Title"),
                }}
              ></h5>
              <div className="service_marketing_step_content">
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step3Item1"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step3Item2"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step3Item3"),
                    }}
                  ></li>
                </ul>
              </div>
            </div>

            <div className="service_marketing_step">
              <h6>step. 04</h6>
              <h5
                dangerouslySetInnerHTML={{
                  __html: t("service.step4Title"),
                }}
              ></h5>
              <div className="service_marketing_step_content">
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step4Item1"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step4Item2"),
                    }}
                  ></li>
                  <br></br>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("service.step4Item3"),
                    }}
                  ></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
