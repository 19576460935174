import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import React, { useCallback, useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/clients.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

export default function Clients() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
    window.scrollTo(0, 0);
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imagePath =
    windowWidth <= 767
      ? "./image/mobile/clients-list"
      : "./image/pc/clients-list";

  const [currentSlide, setCurrentSlide] = useState(0);

  const slickRef = useRef(null);

  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);

  const goToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
    slickRef.current.slickGoTo(slideIndex);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  const activeStyle = {
    color: "#F85601",
    fontFamily: "Pretendard",
    fontSize: "1.375rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "118.836%", // 1.634rem
    textDecorationLine: "underline",
  };
  const getStyle = (slideIndex) => {
    return currentSlide === slideIndex ? activeStyle : {};
  };

  // 새로운 활성 슬라이드 버튼 스타일 추가
  const activeButtonStyle = {
    backgroundColor: "#66ddff",
  };
  // 현재 슬라이드에 따라 스타일을 반환하는 함수 수정
  const getButtonStyle = (slideIndex) => {
    return currentSlide === slideIndex ? activeButtonStyle : {};
  };

  return (
    <div>
      <Header />
      <div className="clients_container">
        <h3
          dangerouslySetInnerHTML={{
            __html: t("client.title"),
          }}
        ></h3>
        <div className="clients_list_container">
          <div className="clients_list_item">
            <img src={`${imagePath}/modu.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/agoda.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/yanolja.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/expedia.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/easy.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/hotelpass.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/trip.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/goodday.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/11st.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/allmytour.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/interpark.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/stay.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/hanjin.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/myrealtrip.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/hotelnjoy.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/tidesquare.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/here.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/hanatour.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item">
            <img src={`${imagePath}/naver.svg`} alt="logo"></img>
          </div>
          <div className="clients_list_item"></div>
        </div>
        <h3
          dangerouslySetInnerHTML={{
            __html: t("client.vipTitle"),
          }}
        ></h3>
        <div className="clients_vip_container">
          <div className="clients_vip_item">
            <img src={`${imagePath}/marriott.svg`} alt="marriott"></img>
          </div>
          <div className="clients_vip_item">
            <img src={`${imagePath}/rosewood.svg`} alt="rosewood"></img>
          </div>
          <div className="clients_vip_item">
            <img src={`${imagePath}/newworld.svg`} alt="newworld"></img>
          </div>{" "}
          <div className="clients_vip_item">
            <img src={`${imagePath}/accor.svg`} alt="accor"></img>
          </div>{" "}
          <div className="clients_vip_item">
            <img src={`${imagePath}/hyatt.svg`} alt="hyatt"></img>
          </div>{" "}
          <div className="clients_vip_item">
            <img src={`${imagePath}/ihg.svg`} alt="ihg"></img>
          </div>
        </div>
        <h3
          dangerouslySetInnerHTML={{
            __html: t("client.slideTitle"),
          }}
        ></h3>
        <div className="clients_slider">
          <Slider {...settings} ref={slickRef}>
            <div>
              <div className="clients_slider_container">
                <div className="clients_slide_first_img"></div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("client.slide1"),
                    }}
                  ></p>
                  <div className="clients_slider_index">
                    <div
                      className="slide_button"
                      style={getButtonStyle(0)}
                      onClick={() => goToSlide(0)}
                    ></div>
                    <div
                      className="slide_button"
                      style={getButtonStyle(1)}
                      onClick={() => goToSlide(1)}
                    ></div>
                    <div
                      className="slide_button"
                      style={getButtonStyle(2)}
                      onClick={() => goToSlide(2)}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="clients_slider_container">
                <div className="clients_slide_second_img"></div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("client.slide2"),
                    }}
                  ></p>
                  <div className="clients_slider_index">
                    <div
                      className="slide_button"
                      style={getButtonStyle(0)}
                      onClick={() => goToSlide(0)}
                    ></div>
                    <div
                      className="slide_button"
                      style={getButtonStyle(1)}
                      onClick={() => goToSlide(1)}
                    ></div>
                    <div
                      className="slide_button"
                      style={getButtonStyle(2)}
                      onClick={() => goToSlide(2)}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="clients_slider_container">
                <div className="clients_slide_third_img"></div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("client.slide3"),
                    }}
                  ></p>
                  <div className="clients_slider_index">
                    <div
                      className="slide_button"
                      style={getButtonStyle(0)}
                      onClick={() => goToSlide(0)}
                    ></div>
                    <div
                      className="slide_button"
                      style={getButtonStyle(1)}
                      onClick={() => goToSlide(1)}
                    ></div>
                    <div
                      className="slide_button"
                      style={getButtonStyle(2)}
                      onClick={() => goToSlide(2)}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
          <div onClick={previous} className="left-arrow">
            <img src="./image/pc/l-arrow.svg" alt={"pre-arrow"} />
          </div>
          <div onClick={next} className="right-arrow">
            <img src="./image/pc/r-arrow.svg" alt={"next-arrow"} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
