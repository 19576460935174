import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/contactus.css";
import { validateEmail, validatePhone, isEmpty } from "./validate";
import Loding from "../../components/Loding";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

export default function ContactUs() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  // 로딩 상태 추가
  const [isLoading, setIsLoading] = useState(false);
  // 각 입력 필드에 대한 상태
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [inquiryValue, setInquiryValue] = useState("");
  const [infoChecked, setInfoChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  // 각 입력 필드에 대한 오류 상태
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [inquiryError, setInquiryError] = useState("");
  const [infoError, setInfoError] = useState("");

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
    window.scrollTo(0, 0);

    const updateErrorMessages = () => {
      if (nameError) {
        setNameError(t("validation.name"));
      }
      if (emailError) {
        setEmailError(t("validation.email"));
      }
      if (phoneError) {
        setPhoneError(t("validation.contact"));
      }
      if (inquiryError) {
        setInquiryError(t("validation.inquiry"));
      }
      if (infoError) {
        setInfoError(t("validation.agree"));
      }
    };

    i18n.on("languageChanged", updateErrorMessages);

    return () => {
      i18n.off("languageChanged", updateErrorMessages);
    };
  }, [nameError, emailError, phoneError, inquiryError, infoError, t]);

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, "");
    let formatted;
    // 지역번호가 '02'인 경우 (서울 지역)
    if (input.startsWith("02")) {
      if (input.length <= 2) {
        formatted = input;
      } else if (input.length <= 5) {
        // '02' 지역번호 뒤에 최대 3자리까지 허용
        formatted = `${input.slice(0, 2)}-${input.slice(2)}`;
      } else if (input.length <= 9) {
        // 전체 길이가 9자리 이하일 경우
        formatted = `${input.slice(0, 2)}-${input.slice(2, 5)}-${input.slice(
          5
        )}`;
      } else {
        // 전체 길이가 10자리인 경우
        formatted = `${input.slice(0, 2)}-${input.slice(2, 6)}-${input.slice(
          6
        )}`;
      }
    } else {
      // 그 외의 경우 (일반적인 010, 011 등의 휴대폰 번호 또는 다른 지역번호)
      if (input.length <= 3) {
        formatted = input;
      } else if (input.length <= 6) {
        formatted = `${input.slice(0, 3)}-${input.slice(3)}`;
      } else if (input.length <= 10) {
        formatted = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(
          6
        )}`;
      } else {
        formatted = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(
          7
        )}`;
      }
    }
    setPhoneValue(formatted);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    // 입력 데이터 가져오기
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const inquiry = e.target.inquiry.value;
    const info = e.target.info.checked;
    const marketing = e.target.marketing.checked;

    // 유효성 검증 및 오류 메시지 설정
    if (isEmpty(name)) {
      setNameError(
        t(language === "en" ? "validation.name" : "validation.name")
      );
      isValid = false;
    } else {
      setNameError("");
    }

    if (!validateEmail(email)) {
      setEmailError(
        t(language === "en" ? "validation.email" : "validation.email")
      );
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!validatePhone(phone)) {
      setPhoneError(
        t(language === "en" ? "validation.contact" : "validation.contact")
      );
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (isEmpty(inquiry)) {
      setInquiryError(
        t(language === "en" ? "validation.inquiry" : "validation.inquiry")
      );
      isValid = false;
    } else {
      setInquiryError("");
    }

    // 체크박스 검증
    if (!info) {
      // info가 false라면 필수 동의가 체크되지 않은 것입니다.
      setInfoError(
        t(language === "en" ? "validation.agree" : "validation.agree")
      );
      isValid = false;
    } else {
      setInfoError("");
    }

    if (!isValid) {
      // 유효하지 않은 입력이 있으면 여기서 함수 종료
      return;
    }

    try {
      setIsLoading(true);

      const data = {
        name: nameValue,
        email: emailValue,
        phone: phoneValue,
        inquiry: inquiryValue,
        info: infoChecked,
        marketing: marketingChecked,
      };

      await axios.post("contact-us", { data });
      // const response = await axios({
      //   method: "POST",
      //   url: "contact-us",
      //   data: { data },
      // });

      alert("문의가 완료되었습니다.");
      // console.log(data);
      setNameValue("");
      setEmailValue("");
      setPhoneValue("");
      setInquiryValue("");
      setInfoChecked(false);
      setMarketingChecked(false);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      alert("문의에 실패하였습니다.");
    }
  };

  return (
    <div>
      <Header />
      {isLoading ? (
        <div className="loading-container">
          <Loding />
        </div>
      ) : null}
      <div className="contact_us_container">
        <div className="contact_us_child_container">
          <div className="contact_us_text_container">
            <h1
              dangerouslySetInnerHTML={{
                __html: t("contact.title"),
              }}
            ></h1>
            <p
              dangerouslySetInnerHTML={{
                __html: t("contact.description"),
              }}
            ></p>
          </div>
          <div className="contact_us_form_container">
            <form onSubmit={handleSubmit}>
              <div className="contact_us_input_container">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: t("contact.nameTitle"),
                  }}
                ></h6>
                <input
                  name="name"
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                  placeholder={t("contact.namePlaceholder")}
                ></input>
                {nameError && <div className="input_error">{nameError}</div>}
              </div>
              <div className="contact_us_input_container">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: t("contact.mailTitle"),
                  }}
                ></h6>
                <input
                  name="email"
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  placeholder={t("contact.mailPlaceholder")}
                ></input>
                {emailError && <div className="input_error">{emailError}</div>}
              </div>
              <div className="contact_us_input_container">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: t("contact.contactTitle"),
                  }}
                ></h6>
                <input
                  name="phone"
                  value={phoneValue}
                  onChange={handlePhoneChange}
                  placeholder={t("contact.contactPlaceholder")}
                  maxLength={13}
                ></input>
                {phoneError && <div className="input_error">{phoneError}</div>}
              </div>
              <div className="contact_us_input_container">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: t("contact.inquiryTitle"),
                  }}
                ></h6>
                <textarea
                  name="inquiry"
                  value={inquiryValue}
                  onChange={(e) => setInquiryValue(e.target.value)}
                  style={{ height: "8.75rem" }}
                  placeholder={t("contact.inquiryPlaceholder")}
                ></textarea>
                {inquiryError && (
                  <div className="input_error">{inquiryError}</div>
                )}
              </div>
              <div className="contact_us_info_container">
                <input
                  name="info"
                  checked={infoChecked}
                  onChange={(e) => setInfoChecked(e.target.checked)}
                  type="checkbox"
                ></input>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("contact.agree"),
                  }}
                ></p>
              </div>
              {infoError && <div className="input_error">{infoError}</div>}
              <div className="contact_us_marketing_container">
                <input
                  name="marketing"
                  checked={marketingChecked}
                  onChange={(e) => setMarketingChecked(e.target.checked)}
                  type="checkbox"
                ></input>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("contact.marketing"),
                  }}
                ></p>
              </div>
              <button
                type="submit"
                dangerouslySetInnerHTML={{
                  __html: t("contact.btn"),
                }}
              ></button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
