import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/header";
import Sales from "../components/sales/sales";
import Footer from "../components/footer/footer";
import "../../styles/main.css";
import Banner from "../components/Banner";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

export default function Main() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    // 언어 변경 이벤트 리스너
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };
    i18n.on("languageChanged", handleLanguageChange);
    // 이벤트 리스너 해제
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      t("key"); // translated
    });
  };

  // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //knowhow
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const arrowImage =
    windowWidth <= 767
      ? "./image/mobile/moreArrow.svg"
      : "./image/pc/moreArrow.svg";

  const logo =
    windowWidth <= 767
      ? "./image/mobile/brochure-logo.svg"
      : "./image/pc/brochure-logo.svg";

  return (
    <div>
      <Header />
      <Banner />
      <div className="brochure_child_container">
        <div className="brocure_logo">
          <img src={logo}></img>
        </div>
        <div className="brochure_text">
          <h3>{t("main.brochureTitle")}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: t("main.brochureDescription"),
            }}
          ></p>
          <p dangerouslySetInnerHTML={{ __html: t("main.brochureDetail") }}></p>
        </div>
      </div>
      <div className="knowhow_container">
        <div className="knowhow_child_container">
          <div className="knowhow_text">
            <h6>About us</h6>
            <h1
              dangerouslySetInnerHTML={{ __html: t("main.knowhowTitle") }}
            ></h1>
            <p
              dangerouslySetInnerHTML={{ __html: t("main.knowhowDescription") }}
            ></p>
            <Link to={"/about-us"}>
              <span>
                {t("main.knowhowMore")}
                <img src={arrowImage} style={{ marginLeft: "5px" }}></img>
              </span>
            </Link>
          </div>
        </div>
        <div className="main_profile_image"></div>
      </div>
      <Sales />
      <Footer />
    </div>
  );
}
