import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../styles/banner.css";

export default function Banner() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
    fade: true,
  };

  return (
    <>
      <Slider {...settings} className="custom-slider">
        <div>
          <div className="main_banner1"></div>
        </div>

        <div>
          <div className="main_banner2"></div>
        </div>

        <div>
          <div className="main_banner3"></div>
        </div>
      </Slider>
    </>
  );
}
