import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import "../../styles/aboutus.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

export default function AboutUs() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="about_us_container">
        <div className="about_us_banner">
          <div className="about_us_banner_text">
            <h3>About Us</h3>
            <h2
              dangerouslySetInnerHTML={{
                __html: t("main.aboutBannerTitle"),
              }}
            ></h2>
          </div>
        </div>
        <div className="about_us_info">
          <h6
            dangerouslySetInnerHTML={{
              __html: t("main.aboutInfo"),
            }}
          ></h6>
        </div>
        <div className="about_us_profile_container">
          <div className="about_us_profile"></div>
          <div className="about_us_profile_info">
            <h3>Regional Team</h3>
            <h4>Co-Founder & CEO</h4>
            <h5
              dangerouslySetInnerHTML={{
                __html: t("main.aboutProfileDes"),
              }}
            ></h5>
            <p
              dangerouslySetInnerHTML={{
                __html: t("main.aboutProfileSub"),
              }}
            ></p>
          </div>
        </div>
        <div className="about_us_contact">
          <div>
            <h2
              dangerouslySetInnerHTML={{
                __html: t("main.aboutContactTitle"),
              }}
            ></h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t("main.aboutContactDescription"),
              }}
            ></p>
            <Link to={"/contact-us"}>
              <button
                className="about_us_contact_btn"
                dangerouslySetInnerHTML={{
                  __html: t("main.aboutContactBtn"),
                }}
              ></button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
