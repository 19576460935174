import React, { useEffect } from "react";
import "./App.css";
import Main from "./app/pages/main/main";
import ContactUs from "./app/pages/contactus";
import NotFound from "./app/pages/notfound";
import { Routes, Route, Link } from "react-router-dom";
import AboutUs from "./app/pages/aboutus";
import Service from "./app/pages/service";
import Clients from "./app/pages/clients";
import i18n from "./locales/i18n";

function App() {
  useEffect(() => {
    const updateLanguage = (lng) => {
      document.documentElement.setAttribute("lang", lng);
    };

    updateLanguage(i18n.language); // 초기 언어 설정
    i18n.on("languageChanged", updateLanguage); // 언어 변경 이벤트 리스너

    return () => {
      i18n.off("languageChanged", updateLanguage); // 컴포넌트 언마운트 시 이벤트 리스너 제거
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/service" element={<Service />} />
        <Route path="/clients-partners" element={<Clients />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
