import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../styles/header.css";
import i18n from "../../../../locales/i18n"; // i18n 임포트

export default function Header() {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태

  // 언어 전환 함수
  const toggleLanguage = () => {
    const newLang = i18n.language === "ko" ? "en" : "ko";
    i18n.changeLanguage(newLang);
  };

  // 현재 언어에 따른 버튼 텍스트
  const languageButtonText = i18n.language === "ko" ? "English" : "Korea";

  // 현재 경로를 확인하는 함수
  const isActive = (path) => {
    return location.pathname === path;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const headerLogoWhite =
    windowWidth <= 767
      ? "./image/mobile/header-logo-white.svg"
      : "./image/pc/header-logo-white.svg";

  const headerLogoBlack =
    windowWidth <= 767
      ? "./image/mobile/header-logo-black.svg"
      : "./image/pc/header-logo-black.svg";

  // /contact-us 경로에서는 headerLogoBlack을 사용
  const headerLogo =
    isActive("/communication") ||
    isActive("/clients-partners") ||
    isActive("/contact-us")
      ? headerLogoBlack
      : headerLogoWhite;

  const olStyle =
    isActive("/communication") ||
    isActive("/clients-partners") ||
    isActive("/contact-us")
      ? { color: "#1b1b1b" }
      : { color: "#FFF" };

  const mobileMenu =
    isActive("/communication") ||
    isActive("/clients-partners") ||
    isActive("/contact-us")
      ? "./image/mobile/header-menu-black.svg"
      : "./image/mobile/header-menu-white.svg";

  useEffect(() => {
    // 모달 상태에 따라 body의 overflow 스타일 조정
    if (isModalOpen) {
      document.body.style.overflow = "hidden"; // 모달이 열리면 스크롤 막기
    } else {
      document.body.style.overflow = "auto"; // 모달이 닫히면 스크롤 가능하게
    }
  }, [isModalOpen]); // 의존성 배열에 isModalOpen 추가

  // 모달을 표시하거나 숨기는 함수
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // 언어 변경 함수
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div className="header_container">
        <div className="header_child_container">
          <div className="header_logo">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={headerLogo}></img>
            </Link>
          </div>
          <div className="header_mobile_menu" onClick={toggleModal}>
            <img src={mobileMenu}></img>
          </div>
          <div className="header_nav_container">
            <ul>
              <Link to="/about-us">
                <ol style={olStyle}>About Us</ol>
              </Link>
              <Link to="/service">
                <ol style={olStyle}>Service</ol>
              </Link>
              <Link to="/communication">
                <ol style={olStyle}>Communication</ol>
              </Link>
              <Link to="/clients-partners">
                <ol style={olStyle}>Clients & Partners</ol>
              </Link>
              <Link to="/contact-us">
                <ol style={olStyle}>Contact us</ol>
              </Link>
            </ul>
            <button className="header_lang_btn" onClick={toggleLanguage}>
              <img src="/image/pc/lang.svg"></img>
              {languageButtonText}
            </button>
          </div>
        </div>
      </div>
      {/* 모달 컴포넌트 */}
      {isModalOpen && (
        <div
          className={`header_modal ${isModalOpen ? "active" : ""}`}
          style={{ background: "transparent" }}
        >
          <div
            className="hambuger_menu"
            style={{ width: "94.44%", background: "#FFFFFF" }}
          >
            <div className="hambuger_close" onClick={toggleModal}>
              <img src="/image/mobile/close.svg"></img>
            </div>
            <div className="hambuger_nav">
              <ul>
                <Link
                  to="/about-us"
                  style={{
                    textDecoration: "none",
                    textDecoration: isActive("/about-us")
                      ? "underline"
                      : "none",
                    color: "#232323",
                  }}
                >
                  <li>About Us</li>
                </Link>
                <Link
                  to="/service"
                  style={{
                    textDecoration: "none",
                    textDecoration: isActive("/service") ? "underline" : "none",
                    color: "#232323",
                  }}
                >
                  <li>Service</li>
                </Link>
                <Link
                  to="/communication"
                  style={{
                    textDecoration: "none",
                    textDecoration: isActive("/communication")
                      ? "underline"
                      : "none",
                    color: "#232323",
                  }}
                >
                  <li>Communication</li>
                </Link>
                <Link
                  to="/clients-partners"
                  style={{
                    textDecoration: "none",
                    textDecoration: isActive("/clients-partners")
                      ? "underline"
                      : "none",
                    color: "#232323",
                  }}
                >
                  <li>Clients & Partners</li>
                </Link>
                <Link
                  to="/contact-us"
                  style={{
                    textDecoration: "none",
                    textDecoration: isActive("/contact-us")
                      ? "underline"
                      : "none",
                    color: "#232323",
                  }}
                >
                  <li>Contact us</li>
                </Link>
              </ul>
              <button className="header_lang_btn" onClick={toggleLanguage}>
                <img src="/image/mobile/lang3.svg"></img>
                {languageButtonText}
              </button>
            </div>
            <div className="hambuger_footer">
              <ul>
                <a
                  href="https://rural-act-846.notion.site/a6ea264bd1e34f45aff1efe055081b8b?pvs=4"
                  target="_blank"
                  style={{ color: "#888" }}
                >
                  <ol>개인정보보호</ol>
                </a>
                {/* <ol>법적고지</ol>
                <ol>쿠키</ol>
                <ol>접근성</ol>
                <ol>사이트맵</ol> */}
              </ul>
              <p>
                서울특별시 서초구 매헌로 16, 13층 1313호 알에이 11<br></br>
                이메일 문의 copilotz@copilotz.com<br></br>사업자 등록번호
                162-81-02886<br></br>대표 박철웅
              </p>
              <p>ⓒ 2023. copilotz+. all rights reserved.</p>
            </div>
          </div>
          <div
            style={{ width: "5.56%", background: "rgba(0, 0, 0, 0.20)" }}
          ></div>
        </div>
      )}
    </>
  );
}
