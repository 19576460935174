// 이메일 검증 함수
export const validateEmail = (email) => {
  const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  return regex.test(email);
};

// 전화번호 검증 및 형식 맞추기 함수
export const validatePhone = (phone) => {
  const cleaned = phone.replace(/[^0-9]/g, "");
  const match = cleaned.match(/^(\d{2,3})(\d{3,4})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};

// 빈값 검증 함수
export const isEmpty = (value) => {
  return value.trim() === "";
};
